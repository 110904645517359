import React from 'react';
import Navbar from './components/Navbar/Navbar';
import telaPrincipal from './assets/sections/img-telaPrincipal.png';
import oQueSomos from './assets/sections/OQueSomos.png'
import avalieAvanco from './assets/sections/AvalieAvanco.png';
import organizacao from './assets/sections/Organizacao.png';
import organizacaoMobile from './assets/sections/Organizacao-mobile.png';
import logo from './assets/logo-text-white.svg';
import { CgCheck, CgInfinity } from 'react-icons/cg'
// import { AiFillCheckCircle } from 'react-icons/ai';
import { BiHelpCircle } from 'react-icons/bi';
import Popup from 'reactjs-popup';
import "reactjs-popup/dist/index.css";

import preco from './assets/sections/img-preco.png';
import { scroller } from 'react-scroll';
const App: React.FC = () => {

  function FscrollTo(elementId: string, offset?: number) {
    scroller.scrollTo(elementId, {
      duration: 200,
      delay: 0,
      // smooth: 'easeInOutQuart',
      offset: window.innerWidth >= 762 ? (offset ?? 0) : -10
    });
  }
  
  return (
    <>
      <Navbar />
      <section id="TelaPrincipal" className="lg:mb-[10vh] lg:h-[90vh] lg:max-h-[800px] h-screen overflow-hidden w-full bg-gradient-to-r from-lb/50 to-white mb-section">
        <div className='container mx-auto'>
          <div className="grid lg:grid-cols-2 grid-cols-1 lg:h-[90vh] lg:max-h-[800px] h-screen items-center justify-center">
            <div className="text-lb-dark z-10">
              <p className="font-medium text-sm">Coletar faz parte</p>
              <h1 className="text-3xl mb-4 font-bold">
                {/* Coleta de dados eficiente e personalizada */}
                Transforme sua coleta de dados: personalize, simplifique, efetive.
              </h1>
              <p className="text-xl mb-5">
                {/* Liberte todo o potencial de suas estratégias de marketing com Leadboard - a plataforma definitiva para criação de formulários personalizados, captação de leads e transformação deles em oportunidades valiosas para o seu negócio. */}
                {/* Plataforma de criação de formulários líder em customização. Com uma interface intuitiva e fácil de usar, você pode criar formulários online em questão de minutos. Nossa ampla variedade de recursos e opções de personalização garante que você possa capturar exatamente as informações que precisa. Comece agora a criar seus formulários personalizados e simplifique sua coleta de dados. */}
                {/* Transforme sua coleta de dados com LeadBoard. Com nossa plataforma de criação de formulários, você pode criar formulários online personalizados em questão de minutos. Não mais formulários genéricos. Com nossos recursos fáceis de usar e opções de personalização, você pode capturar exatamente as informações que precisa. Junte-se aos milhares de usuários satisfeitos e simplifique sua coleta de dados hoje mesmo com LeadBoard. */}
                A solução para sua coleta de dados. Crie formulários online personalizados em minutos. Não mais formulários genéricos. Com nossos recursos intuitivos e opções de personalização, você pode capturar exatamente as informações que precisa. Junte-se aos nossos milhares de usuários satisfeitos e simplifique sua coleta de dados agora.
              </p>
              <button className="lb-btn dark">
                Comece agora mesmo!
              </button>
            </div>
            <div className="lg:h-[90vh] lg:max-h-[800px] z-[0] h-full w-full relative flex items-center">
              <img src={telaPrincipal}
                className="absolute z-[0] lg:opacity-100 opacity-60 bottom-0 lg:left-[-160px] left-0 lg:w-[780px] w-[400px] max-w-none" />
            </div>
          </div>
        </div>
      </section>



      <section id="OQueSomos" className='mb-section container mx-auto'>
        <div className='grid gap-4 lg:grid-cols-2 grid-cols-1'>
          <div className="relative">
            <div className="absolute top-[60px] right-0 h-[80px] w-[80px] rounded-full bg-gradient-to-b from-lb to-lb/20" />
            <div className="absolute bottom-[40px] left-[-32px] h-[120px] w-[120px] rounded-full bg-gradient-to-b from-lb to-lb/20" />
            <img src={oQueSomos} className="w-full max-w-none" />
          </div>
          <div className="lg:text-right sm:min-[0px]:text-center lg:mt-0 mt-8">
            <h2 className="mb-3 text-2xl font-bold text-lb-dark">O que precisa saber do LeadBoard?</h2>
            <p className="text-lb-dark/50">
              Leadboard é uma plataforma inovadora de criação de formulários personalizados, projetada para ajudar empresas a capturar leads qualificados e maximizar o potencial de suas estratégias de marketing.
              <br></br>
              <br></br>
              Com uma interface intuitiva e fácil de usar, a Leadboard permite criar formulários atraentes e funcionais para coleta de informações dos leads, como nome, endereço de e-mail, número de telefone e outros dados relevantes.
            </p>
          </div>
        </div>
      </section>

      <section id='AnaliseAvanco' className='mb-section container mx-auto'>
        <div className='grid lg:grid-cols-2 grid-cols-1 gap-4'>
          <div className="text-left lg:order-[1 !important] order-2">
            <h2 className="mb-3 text-2xl font-bold text-lb-dark">Avalie o seu avanço</h2>
            <p className="text-lb-dark/50">
              Monitore seu progresso e alcance seus objetivos de marketing com a Leadboard. Com ela, você pode acompanhar o desempenho de suas estratégias de marketing e avaliar o seu progresso em relação aos seus objetivos.              </p>
          </div>
          <div className="lg:order-2 order-1">
            <img src={avalieAvanco} className="w-full max-w-none" />
          </div>
        </div>
      </section>

      <section id="Preco" className='mb-section container mx-auto'>
        <h2 className="mb-3 text-2xl text-center font-bold text-lb-dark">Preço</h2>
        <div className='grid lg:grid-cols-6 grid-cols-1'>
          <div className='relative lg:col-start-2 lg:col-span-4 col-start-1'>
            <img src={preco}
              className="absolute z-10 top-[42px] w-[272.53px] hidden lg:block "
              style={{ left: "calc(100% - 116.46px)" }}
            />
            <div className='rounded-lg relative bg-white'>

              <div className='bg-gray-300/40 mb-8 px-4 py-[60px] rounded flex flex-col items-center justify-center'>
                <p className='text-4xl text-dark font-bold'>R$ 99,00 <span className='text-dark/30 text-[1.2rem]'>/mês</span></p>
                <p className='font-bold'>Por gerente</p>
              </div>

              <div className='flex flex-col items-center w-full'>
                <h4 className="text-[1.4rem] font-bold text-lb">10.000</h4>
                <p className='text-[1rem] font-bold'>Envios por formulário</p>
              </div>
              {
                [
                  { label: "Limite de formulários", content: "Com o plano pago, você tem acesso a formulários ilimitados, o que significa que pode criar quantos formulários quiser sem restrições!", infinity: true },
                  { label: "Visualizações mensais", content: "O número ilimitado de visualizações significa que você não precisa se preocupar com restrições quanto ao número de visitantes que podem ver o seus formulários.", infinity: true },
                  { label: "Usuários por gerentes", content: "Você pode adicionar quantos usuários quiser como gerentes, sem limitações! Ideal para equipes colaborativas que precisam acessar e gerenciar formulários juntas.", infinity: true },
                  { label: "Coletores por gerentes", content: "Você tem acesso a coletores de leads ilimitados, permitindo que você e sua equipe adicionem quantos coletores de leads quiserem para maximizar a captura de leads!", infinity: true },
                  { label: "Acessos a API", content: "Você tem acesso API ilimitado, permitindo integrações com outras ferramentas e automação de marketing sem restrições, para uma captura de leads ainda mais eficiente!", infinity: true },
                  { label: "Suporte prioritário em tempo real", content: "texto de exemplo5", infinity: false, help: false },
                ].map((e, index) => (
                  <div key={index} className='p-4 flex flex-col items-center'>
                    {
                      e.infinity ? <CgInfinity fontSize={42} className="text-lb-dark" /> : <CgCheck fontSize={64} className="text-lb" />
                    }
                    <div className='flex items-center space-x-2'>
                      <p className="font-bold text-dark">{e.label}</p>
                      {
                        (e.help === undefined || e.help === true) && (
                          <Popup trigger={
                            <div className='cursor-pointer'>
                              <BiHelpCircle fontSize={20} />
                            </div>}
                            contentStyle={{
                              width: 300
                            }}
                            closeOnDocumentClick
                            position="bottom right"
                            on={['focus', 'hover', 'click']}
                          >
                            <span>
                              {e.content}
                            </span>
                          </Popup>
                        )
                      }
                    </div>
                  </div>
                ))
              }

              <hr className='w-auto mx-4' />

              <div className='px-4 w-full py-6'>
                <button className='lb-btn brown w-full'>
                  Comece agora mesmo
                </button>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section id="Organizacao" className='container mx-auto mb-8 lg:mb-0'>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div>
            <img src={organizacao} className="hidden lg:block" />
            <img src={organizacaoMobile} className="block lg:hidden" />
          </div>
          <div>
            <div className="text-left lg:order-[1 !important] order-2">
              <p className="font-medium text-sm">Organize</p>
              <h2 className="mb-3 text-2xl font-bold text-lb-dark">Crie uma organização!</h2>
              <p className="text-lb-dark/50 mb-4">
                Você pode construir sua marca de forma eficaz e aprimorar sua presença online, capturando leads qualificados e maximizando o potencial de suas estratégias de marketing.
              </p>
              <p className='text-lb-dark/50 mb-6'>
                A plataforma oferece recursos intuitivos de criação de formulários personalizados, segmentação de leads e análise de dados, tornando-a a escolha perfeita para empresas em crescimento que buscam o sucesso. Não importa o tamanho de sua organização, a Leadboard ajuda você a fazer sua marca brilhar.
              </p>
              <button className='lb-btn brown'>
                Crie uma organização agora mesmo!
              </button>
            </div>
          </div>
        </div>
      </section>

      <footer className='bg-gradient-to-r from-dark to-lb-dark px-8 pt-8 pb-3'>
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 space-y-4 lg:space-y-0 mb-8">
          <div>
            <img src={logo} className="h-[32px]" />
          </div>
          <div>
            <strong className="text-white/50 text-[.72rem]">Navegação</strong>
            <ul className='space-y-1'>
              <li><p className='cursor-pointer text-white text-lg' onClick={() => FscrollTo("TelaPrincipal")}>Tela principal</p></li>
              <li><p className='cursor-pointer text-white text-lg' onClick={() => FscrollTo("OQueSomos", -200)}>O que somos</p></li>
              <li><p className='cursor-pointer text-white text-lg' onClick={() => FscrollTo("AnaliseAvanco", -200)}>Analise seu avanço</p></li>
              <li><p className='cursor-pointer text-white text-lg' onClick={() => FscrollTo("Preco", -200)}>Preço</p></li>
              <li><p className='cursor-pointer text-white text-lg' onClick={() => FscrollTo("Organizacao", -200)}>Organização</p></li>
            </ul>
          </div>
          <div>
            <strong className="text-white/50 text-[.72rem]">Outros</strong>
            <ul className='space-y-1'>
              <li><p className='cursor-pointer text-white text-lg'>Termo de uso</p></li>
              <li><p className='cursor-pointer text-white text-lg'>Privacidade</p></li>
            </ul>
          </div>
        </div>
        <div className='text-center text-white/50 text-[12px]'>
          Soluções Virtuais 2023 - Todos os direitos reservados.
        </div>
      </footer>
    </>
  );
}

export default App;