import React, { useState } from 'react';
import logo from "../../assets/logo-text.png";
import { FiMenu } from 'react-icons/fi'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { scroller } from 'react-scroll'
const Navbar: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 762);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [scrollY, setScrollY] = useState<number>(window.scrollY)

  function FscrollTo(elementId: string, offset?: number) {
    scroller.scrollTo(elementId, {
      duration: 200,
      delay: 0,
      // smooth: 'easeInOutQuart',
      offset: window.innerWidth >= 762 ? (offset ?? 0) : -10
    });
    setMobileOpen(false)
  }

  window.onresize = () => {
    setIsMobile(window.innerWidth <= 762);
    setMobileOpen(false);
  };
  window.addEventListener("scroll", () => {
    setScrollY(window.scrollY)
  })

  const LinkNavigation: React.FC<{ label: string, moveTo: string, offset?: number }> = (prop) => {
    return (
      <p className='flex items-center cursor-pointer' onClick={() => FscrollTo(prop.moveTo, prop.offset)}>
        {prop.label}
      </p>
    )
  }

  return (
    <nav className={`fixed h-[80px] w-screen z-20 flex items-center ${scrollY === 0 ? "bg-transparent" : ("bg-white")}`}>
      <div className='navbar'>
        <img src={logo} className="h-[32px]" alt='Logo' />
        {
          !isMobile && (
            <div className='space-x-5 flex'>
              <LinkNavigation label='Tela Principal' moveTo='TelaPrincipal' />
              <LinkNavigation label='O que somos' moveTo='OQueSomos' offset={-200} />
              <LinkNavigation label='Analize seu avanço' moveTo='AnaliseAvanco' offset={-200} />
              <LinkNavigation label='Preço' moveTo='Preco' offset={-200} />
              <LinkNavigation label='Organização' moveTo='Organizacao' offset={-200} />
              <button className='lb-btn small outline primary'>
                Entrar
              </button>
            </div>
          )
        }

        {/* VERSÃO MOBILE */}
        <div className={mobileOpen ? "overlow-menu-open" : "overlow-menu-close"} onClick={() => setMobileOpen(false)} />
        <div className={mobileOpen ? "navbar-menu-open" : "navbar-menu-close"}>
          <div className='bg-white w-screen rounded-t-[18px] h-[50vh] '>
            <div className='flex text-[20px] space-y-3 flex-col justify-center h-full items-center'>
              <LinkNavigation label='Tela Principal' moveTo='TelaPrincipal' />
              <LinkNavigation label='O que somos' moveTo='OQueSomos' offset={-200} />
              <LinkNavigation label='Analize seu avanço' moveTo='AnaliseAvanco' offset={-200} />
              <LinkNavigation label='Preço' moveTo='Preco' offset={-200} />
              <LinkNavigation label='Organização' moveTo='Organizacao' offset={-200} />
              <button className='btn-outline'>
                Entrar
              </button>
            </div>
          </div>
        </div>
        {
          isMobile && (
            <button onClick={() => setMobileOpen(!mobileOpen)} className="cursor-pointer fixed bottom-6 right-6 w-[64px] h-[64px] bg-lb-dark text-white flex justify-center items-center rounded-full">
              {mobileOpen ? (
                <AiOutlineCloseCircle />
              ) : (
                <FiMenu />
              )}
            </button>
          )
        }
      </div >
    </nav>
  );
}

export default Navbar;